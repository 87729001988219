import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import { key, store } from './store';

/* Globally Imported Components */
import {
  IonicVue,
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
  IonRippleEffect
} from '@ionic/vue';

/* Ion Icons */
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store, key);

app.component('ion-content', IonContent);
app.component('ion-page', IonPage);
app.component('ion-icon', IonIcon);
app.component('ion-button', IonButton);
app.component('ion-ripple-effect', IonRippleEffect);

addIcons({
  close
});

router.isReady().then(() => {
  app.mount('#app');
});
