<ion-menu side="start" content-id="main">
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>
        Eric Zawadski
      </ion-title>
      <ion-buttons slot="end">
        <ion-button @click="closeMenu">
          <ion-icon slot="icon-only" icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item
        class="ion-activatable ripple-parent"
        button
        @click="goTo('home')"
      >
        Home
        <ion-ripple-effect></ion-ripple-effect>
      </ion-item>
      <ion-item
        class="ion-activatable ripple-parent"
        button
        @click="goTo('learning')"
      >
        Learning
        <ion-ripple-effect></ion-ripple-effect>
      </ion-item>
      <ion-item
        class="ion-activatable ripple-parent"
        button
        @click="goTo('projects')"
      >
        Projects
        <ion-ripple-effect></ion-ripple-effect>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>
